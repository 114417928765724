/* Center the loader */
.loader {
    left: 50%;
    top: 50%;
    z-index: 1112;
}

.loader-backdrop {
    background-color:#000;
    position:fixed;
    width: 100%;
    height: 100%;
    z-index:1111;
    opacity:0.35;
    text-align:center;
}

.loader-container{
    opacity:0;
    width:100%;
    height: 100%;
    position:absolute;
    display:none;
    z-index: 1115;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader-container-active{
    opacity:1;
    animation: appearGradually 0.3s ease-in;
}


@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes appearGradually {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes disappearGradually {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}


