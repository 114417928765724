.viewportContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.viewport {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  /* max-height: 1080px;
  max-width: 1920px; */
}

.backgroundImageContainer {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 1;
}

.backgroundImage {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}
